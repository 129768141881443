.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.subContent {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.subContent:hover {
  transform: scale(1.1);
  transform-origin: left ;
  color:var(--tb-secondary-text-emphasis) !important;
  font-weight: 600;
}

/* Api Docs Css */
@media (max-width: 767px) {
  .navbar-brand span {
    display: none;
  }

  .navbar .me-2 {
    display: block;
  }

  .side-nav {
    position: fixed;
    top: 135px;
    width: 200px;
    height: calc(100vh - 135px);
    background-color: #F8F9FA;
    border-right: 1px solid #E0E0E0;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1050;
  }

  .side-nav.visible {
    transform: translateX(0);
  }

  .swagger-ui .container {
    margin-left: 0;
  }

  .navbar .d-flex {
    width: 100%;
  }

  .offcanvas-body {
    padding: 0;
    overflow-y: auto;
  }

  .offcanvas-body .nav-link {
    padding: 0.5rem 1rem;
  }

  .navbar .d-flex .input-group {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .side-nav {
    position: fixed;
    top: 135px;
    width: 250px;
    transform: translateX(0);
    height: calc(100vh - 135px);
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .swagger-ui .container {
    margin-left: 250px;
    transition: margin-left 0.3s ease;
  }

  .navbar-brand span {
    display: inline-block;
  }

  .navbar .me-2 {
    display: none;
  }
}

.mainApiArea {
  margin-left: 250px;
}
@media (max-width: 768px) {
  .mainApiArea {
    margin-left:0px;
    margin-top:100px;

  }}
  /* Api Docs Css End */