@import "./bootstrap.scss";
@import "./app.scss";
@import "./icons.scss";
@import "./variables.scss";

//rtl SCSS
// @import "rtl/structure/structure";
// @import "rtl/components/components";
// @import "rtl/plugins/plugins";
// @import "rtl/apps/apps";
// @import "rtl/pages/pages";

//Emoji picker
// aside.EmojiPickerReact.epr-main {
//   position: absolute !important;
//   bottom: 82px;
//   z-index: 2;
//   left: 23px;
// }

// [dir="rtl"] .EmojiPickerReact.epr-main {
//   right: 23px !important;
//   left: unset !important;
// }

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px !important;
}

.rc-tooltip-content {
  max-width: 400px;
}

.largeTooltip .rc-tooltip-content {
  max-width: 800px;
}

.react-tagsinput {
  border: var(--#{$prefix}border-width) solid
    var(--#{$prefix}border-color-translucent) !important;
  border-radius: var(--#{$prefix}border-radius) !important;
  background-color: var(--tb-secondary-bg) !important;
}

.react-tagsinput-tag {
  background-color: var(--#{$prefix}primary-bg-subtle) !important;
  color: var(--#{$prefix}primary) !important;
  border: 1px solid var(--#{$prefix}primary-bg-subtle) !important;
}

.react-tagsinput-input {
  width: 105px !important;
}

// summary page accordian
.summary-accordian {
  .lefticon-accordion .accordion-button::after {
    left: 0.5rem;
    top: 20px;
  }
  .accordion-button {
    padding-right: 0px;

    .row {
      width: 100%;
      margin-left: 0px;
    }
  }
  .accordion-button::after {
    margin-left: 10px;
  }
  .accordion-button:not(.collapsed) {
    color: initial;
    background-color: initial;
    box-shadow: none;
  }
}
//

.sub-group-brands-accordian .lefticon-accordion .accordion-button {
  cursor: default;
  &::after {
    display: none !important;
  }
}

.user-name-text2 {
  display: block;
  width: 150px;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.dashsummary .apexcharts-datalabels-group {
  transform: translate(0px, -5px) scale(1) !important;

  & > text:last-child {
    transform: translateY(-10px) !important;
  }
}

[data-bs-theme="dark"] {
  .intl-tel-input .country-list {
    background: var(--tb-secondary-bg);
    color: var(--tb-body-color);
  }

  .intl-tel-input .country-list .country.highlight {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .react-modal-sheet-container {
    background-color: var(--tb-navbar-menu2-bg) !important;
  }
}

.homeSteps {
  input[type="checkbox"] {
    border-radius: 50%;
    width: 1.4em;
    height: 1.4em;
    border-style: dashed;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.summary_modal > div {
  max-width: 1000px;
}

.pdf-container {
  .react-pdf__Page__canvas {
    width: 100% !important; /* Make the canvas take up the full width of the container */
    height: auto !important; /* Maintain aspect ratio */
  }

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    width: 100% !important; /* Match the canvas width */
    height: 100% !important; /* Match the canvas height */
    overflow: hidden; /* Hide any overflow content */
    pointer-events: none; /* Ensure it doesn't intercept pointer events */
  }

  .react-pdf__Page__textContent span {
    display: inline-block; /* Aligns text properly */
  }
}

.pdftablecell {
  padding: 0.75rem 0.6rem;
  border-right: 0 !important;
}

.pdftablecell:last-child {
  border-right: 1px solid black !important;
}

.bg-light-gray {
  background-color: #fbfcff6e;
}

.selectItemWithCheckbox {
  display: flex;
  align-items: center;
  cursor: default;
  font-size: inherit;
  width: 100%;
  user-select: none;
  background-color: transparent;
  color: inherit;
  padding: 8px 12px;
  box-sizing: border-box;
  opacity: 1;
  pointer-events: auto;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba(228, 28, 253, 0.122);
  }
}

// css for tilled card element =====================

.credit-card-icons {
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // margin-top: -2.63rem;
  // margin-right: 1rem;
  // height: 100%;
  // margin-bottom: 0.2rem;

  svg {
    height: 30px;
    width: auto;
  }
}

#card-number-element,
#card-expiration-element,
#card-cvv-element {
  height: 40px;
  display: block;
  width: 100%;
  padding: 0.525rem 0.9rem;
  font-size: var(--tb-font-base);
  font-weight: var(--tb-font-weight-normal);
  line-height: 1.5;
  appearance: none;
  background-color: var(--tb-secondary-bg);
  background-clip: padding-box;
  border: var(--tb-border-width) solid var(--tb-border-color-translucent);
  border-radius: var(--tb-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#card-cvv-element.invalid {
  border-color: red;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.background-auth {
  background: url("../images/signup-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.signup-box {
  margin: 30px;
}

.registration-inner {
  max-width: 600px;
  margin: auto;
  border: 1px solid rgba(251, 252, 255, 1);
}

.bg-gray {
  background-color: rgba(251, 252, 255, 1);
}

.invalid-feedback {
  color: var(--tb-primary) !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--tb-primary) !important;
}

.Toastify__toast--success {
  .Toastify__toast-icon {
    svg {
      fill: var(--tb-secondary);
    }
  }

  .Toastify__progress-bar--wrp > div {
    background-color: var(--tb-secondary);
  }
}

.Toastify__toast--error {
  .Toastify__toast-icon {
    svg {
      fill: var(--tb-primary);
    }
  }

  .Toastify__progress-bar--wrp > div {
    background-color: var(--tb-primary);
  }
}

.otp-input {
  width: 3rem !important;
  height: 3rem !important;

  border: var(--tb-border-width) solid var(--tb-border-color-translucent);
}

.otp-input:focus {
  border: 1px solid var(--tb-primary) !important;
  border-radius: 4px;
  outline: none !important;
}

.custom-col {
  flex: 0 0 20%; /* This ensures that each column takes 20% of the row's width */
  max-width: 20%;
}

.analytics-card {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;

  &.active {
    background-color: rgba(228, 28, 253, 0.15) !important;
  }

  &:hover {
    background-color: rgb(238, 238, 238);
    transition-duration: 0.15s;
    transition-property: background-color;
  }
}

.hover-primary {
  color: black !important;
}

.hover-primary:hover {
  color: var(--tb-primary) !important;
  text-decoration: underline !important;
}

.hover-button:hover {
  background-color: transparent !important;
  border-color: var(--tb-secondary) !important;
  color: var(--tb-secondary) !important;
}
.swagger-ui {
  .information-container {
    display: none;
  }
  .scheme-container {
    box-shadow: none !important;
  }
  .filter-container {
    display: none;
  }
  .btn.authorize svg {
    fill: white !important;
  }

  .info .title small.version-stamp {
    background-color: #e41cfd !important;
    color: white !important;
  }
  .info .description,
  .info .version {
    color: white !important;
  }
  .opblock.opblock-put .opblock-summary-method {
    background-color: #8c8ca1 !important;
  }
  .opblock.opblock-post .opblock-summary-method {
    background-color: #d955f0 !important;
  }
  .opblock.opblock-get .opblock-summary-method {
    background-color: #a4a2fb !important;
  }
  .opblock.opblock-patch .opblock-summary-method {
    background-color: #a4a2fb !important;
  }
  .opblock.opblock-delete .opblock-summary-method {
    background-color: #2b2b2b !important;
  }

  .opblock.opblock-delete {
    background-color: rgba(43, 43, 43, 0.1) !important;
  }
  .opblock.opblock-get {
    background-color: rgba(164, 162, 251, 0.1) !important;
  }
  .opblock.opblock-post {
    background-color: rgba(217, 85, 240, 0.1) !important;
  }
  .opblock.opblock-put {
    background-color: rgba(140, 140, 161, 0.1) !important;
  }
  .opblock.opblock-patch {
    background-color: rgba(164, 162, 251, 0.1) !important;
  }

  .topbar {
    background-color: #6868ab !important;
    color: white !important;
  }

  .btn.authorize {
    background-color: #e41cfd !important;
    border-color: #e41cfd !important;
    color: white !important;
  }

  .info .title {
    color: #2b2b2b !important;
    font-family: "Arial", sans-serif;
  }

  .info .description,
  .info .version {
    color: #8c8ca1 !important;
    font-family: "Arial", sans-serif;
  }
  .topbar {
    display: none !important;
  }
  .opblock.opblock-get .opblock-summary,
  .opblock.opblock-get {
    border-color: #6868ab !important;
  }

  .opblock.opblock-put .opblock-summary,
  .opblock.opblock-put {
    border-color: lightgray !important;
  }

  .opblock.opblock-post .opblock-summary,
  .opblock.opblock-post {
    border-color: #e41cfd !important;
  }

  .opblock.opblock-patch .opblock-summary,
  .opblock.opblock-patch {
    border-color: #6868ab !important;
  }

  .opblock.opblock-delete .opblock-summary,
  .opblock.opblock-delete {
    border-color: Black !important;
  }
  .btn.authorize svg {
    fill: white !important;
  }

  .info .title small.version-stamp {
    background-color: #e41cfd !important;
    color: white !important;
  }
  .info .description,
  .info .version {
    color: white !important;
  }
  .info .title small {
    background-color: #a4a2fb !important;
  }
  .auth-container code {
    color: black !important;
  }
  code {
    color: white;
  }
}

.subgrpDetail {
  .head {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    min-height: 52px;
    background-color: rgb(243, 244, 246);
    border-radius: 0.5rem;
    margin-left: 0px;
    margin-right: 0px;

    .col {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      line-height: normal;
      padding-left: 16px;
      padding-right: 16px;
      justify-content: center;
      text-align: center;
      margin-top: 0px;
    }
  }
}

.subgrpDetail {
  position: relative;
  overflow: visible;
}

.custom-col {
  position: relative;
  z-index: 2;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1060 !important;
}

.brand-container {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: 8px;
}

.brand-card {
  position: relative;
  background: white;
  border-radius: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #edf2f7;
}

.brand-card:hover,
.brand-card--hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.1);
}

.brand-card--active {
  background: #f8fafc;
  border-color: #e2e8f0;
}

.brand-card__content {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.brand-card__icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  flex-shrink: 0;
}

.brand-card__info {
  flex: 1;
  min-width: 0;
}

.brand-card__name {
  font-weight: 600;
  color: #1e293b;
  font-size: 15px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.brand-card__id {
  font-size: 13px;
  color: #64748b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.brand-card__actions {
  opacity: 0;
  transform: translateX(10px);
  transition: all 0.3s ease;
}

.brand-card:hover .brand-card__actions,
.brand-card--active .brand-card__actions {
  opacity: 1;
  transform: translateX(0);
}

.brand-card__action-btn {
  background: transparent;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: #6366f1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.brand-card__action-btn:hover {
  background: rgba(99, 102, 241, 0.1);
  transform: scale(1.1);
}

.brand-card__active-indicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #6366f1;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.empty-state {
  text-align: center;
  padding: 48px 24px;
  background: #f8fafc;
  border-radius: 12px;
  border: 2px dashed #e2e8f0;
}

.empty-state__icon {
  width: 64px;
  height: 64px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
  color: #6366f1;
  font-size: 24px;
  box-shadow: 0 4px 12px -2px rgba(99, 102, 241, 0.2);
}

.empty-state__title {
  color: #1e293b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.empty-state__description {
  color: #64748b;
  font-size: 14px;
  max-width: 200px;
  margin: 0 auto;
}

/* Search highlight styling */
.text-primary.fw-semibold {
  background: linear-gradient(
    120deg,
    rgba(99, 102, 241, 0.2) 0%,
    rgba(99, 102, 241, 0.1) 100%
  );
  border-radius: 4px;
  padding: 2px 4px;
  margin: 0 -2px;
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.brand-card {
  animation: slideIn 0.3s ease-out forwards;
  animation-delay: calc(var(--index) * 0.05s);
}

/* Add to your stylesheet */
.media-popover {
  max-width: none !important;
  width: auto !important;
}

.media-container {
  min-width: 100px;
  max-width: 440px;
}

.media-section {
  margin-bottom: 16px;
}

.media-section:last-child {
  margin-bottom: 0;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.loader-image {
  width: 40px;
  height: 40px;
}

.media-grid {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.media-item {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  height: 100px;
  width: 100px;
  aspect-ratio: 1; // Maintains square shape
}

.media-link {
  display: block;
  height: 100%;
  text-decoration: none;
}

.media-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s;
}

.media-item:hover .media-overlay {
  opacity: 1;
}

.file-size {
  font-weight: 500;
}

.download-icon {
  font-size: 16px;
  cursor: pointer;
}

.download-icon:hover {
  color: #ccc;
}

.no-media {
  text-align: center;
  padding: 20px;
  color: #666;
}

.media-count {
  font-size: 12px;
  background: #6c757d;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 4px;
}
.gallery-icon-wrapper {
  display: inline-block;
}

.gallery-icon-wrapper i {
  font-size: 1.25rem;
  position: relative;
  display: inline-block;
}

.media-count-badge {
  font-size: 0.65rem;
  padding: 0.25em 0.6em;
  background-color: #ff69b4 !important;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(25%, -25%) !important;
  left: 30% !important;
  top: 2px !important;
}

/* Optional: Add hover effect */
.gallery-icon-wrapper:hover {
  opacity: 0.8;
  cursor: pointer;
}

.rbmContainer {
  .formContainer {
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    display: flex;
    .formContainer {
      width: calc(100% - 450px);
      margin-bottom: 0px;
    }
    .preview {
      width: 450px;
    }
  }
}

.react-tagsinput.disabled {
  color: var(--tb-tertiary-color);
  background-color: var(--tb-tertiary-bg) !important;
  opacity: 1;
}

/* Emoji Picker */

.gpr-search-container input.gpr-search:focus {
  border-color: var(--#{$prefix}primary) !important;
}

.gpr-category:hover {
  box-shadow: 0 0 0 2px var(--#{$prefix}primary) !important;
}

.gpr-result-image:hover {
  box-shadow: 0 0 0 2px var(--#{$prefix}primary) !important;
}

.GifPickerReact .gpr-icn-clear-search,
.epr-btn > .epr-icn-clear-search {
  background-image: url("../images/close.svg");
}
.emoji-picker-react,
.gif-picker-container {
  position: absolute !important;
  bottom: calc(100% + 8px) !important;
  right: 0 !important;
  border-radius: 12px !important;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}

.EmojiPickerReact input:focus {
  border-color: var(--#{$prefix}primary) !important;
}

.epr-skin-tone-select > button::after {
  border-color: var(--#{$prefix}primary) !important;
}

.epr-category-nav > button.epr-active {
  border-color: var(--#{$prefix}primary) !important;
}

.epr-emoji-category-content > button:hover {
  background-color: var(--#{$prefix}primary-bg-subtle) !important;
}

.epr-category-nav > button {
  background-image: url("../images/icons.svg");

  &::before {
    display: none;
  }
}

.epr-active {
  color: #ff69b4 !important;
}

.dropdown-hover {
  cursor: pointer;
}

.dropdown-hover .dropdown-menu {
  display: none;
  z-index: 1000;
  margin-top: 0.5rem;
  min-width: 200px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-hover:hover .dropdown-menu {
  display: block;
}

.dropdown-hover .dropdown-item:hover {
  background-color: #f8f9fa;
}

.hideIcon+span {
  display: none;
}

.hideIcon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
}
